<template>
  <div>
    <b-card>


      <show @refresh="init" :item="patient_case.patient_case" :load="load" :isVertical="false" @changeTab="move">
        <template #add_tab>
          <b-tab title="Transaction History" :title-item-class="'tab-title-class'">
            <template #title>
              <feather-icon icon="UserIcon" />
              <span>Profile </span>

            </template>

            <list :data="patient_case" :loading="load" :only="['patient_name', 'birth_date', 'gender', 'nationality',
              'last_branch', 'last_branch_code', 'ifa', 'medical_record']"></list>





            <b-table :items="patient_case.patient_case" :fields="cases_fields" style="font-size:12px;">



              <template #cell(status)="row">
                <b-badge pill :variant="`light-${getStatus(row.item.status.status).color}`" class="text-capitalize">
                  {{ row.item.status.name }}


                </b-badge>
              </template>


              <template #cell(id)="row">

                <b-link style="font-weight:bold ; font-size:12px; color:#054978;"
                  :to="{ name: 'case-show', params: { id: row.item.id } }">
                  {{ row.item.id }}
                </b-link>

              </template>

              <!-- <template #cell(patient_type)="row">

        
           
    </template> -->

            </b-table>
          </b-tab>

          <!-- <b-tab>
        <template #title>
          <feather-icon icon="CheckSquareIcon" />
          <span>Cases  </span>
        </template>


        

  
    
       
       </b-tab> -->
        </template>
      </show>
    </b-card>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import show from "@/views/patients/components/show";
import DataTable from '@/views/components/table/DataTable';

import {
  BTab,
  BLink,
  BCardText,
  BCard,
  BBadge,
  BCol,
  BTextArea,
  BRow,
  BTable,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFromRadioGroup,
  BFormRadio,
  BFormTextarea,
} from "bootstrap-vue";
import List from "@/views/components/info/list";

export default {
  name: "patient_case",
  components: {
    DataTable,
    vSelect,
    BBadge,
    show,
    BTab,
    BFormTextarea,
    BTextArea,
    BCardText,
    BCard,
    BCol,
    BLink,
    BRow,
    List,
    BTable,
    BForm,
    BFormGroup,
    BFromRadioGroup,
    BFormInput,
    BFormRadio,
    BButton,
  },
  data() {
    return {
      cases_fields: [

        {
          key: 'id',
          label: 'Case ID',
          sortable: true,
        },
        //  {
        //   key: 'name',
        //   label: 'Patient',
        //   sortable: true,
        // },

        {
          key: 'dr_name',
          label: "Doctor",
          sortable: true,
        },
        {
          key: 'account_no',
          label: 'Account no',
          sortable: true,
        },
        {
          key: 'admission_date',
          label: ' Admission Date',
          sortable: true,
        },
        {
          key: 'assign_date',
          label: 'Assign Date ',
          sortable: true,
        },

        // {
        //   key: 'account_no',
        //   label: 'Visit no',
        //   sortable: true,
        // },
        // {
        //   key: 'invoice[0].serial_no',
        //   label: 'MRN',
        //   sortable: true,
        // },
        // {
        //      key: 'medical_record',
        //   label: 'MRN',
        //   sortable: true,
        // },
        {
          key: 'status',
          label: 'status',
          sortable: true,
        },
        {
          key: 'close_date',
          label: 'Close date',
          sortable: true,
        },
        {
          key: 'branch.name',
          label: 'Branch',
          sortable: true,
        },
        {
          key: 'patient_type',
          label: 'Patient type ',
          sortable: true,
        },
        {
          key: 'assign_date',
          label: 'Assign Date ',
          sortable: true,
        },
        {
          key: 'auditor.name',
          label: "Reviewer",
          sortable: true,
        }
      ],
      operative_procedures_fields: [
        {
          key: "opr_code",
          label: "Code",
        },
        {
          key: "abbreviation",
          label: "Abbreviation ",
        },
        // {
        //   key: "code",
        //   label: "Code ",
        // },
        {
          key: "opr_status",
          label: "status  ",
        },
        {
          key: "opr_date",
          label: "Surgery Date",
        },
        {
          key: "dr_name",
          label: "Doctor Name",
        },

      ],
      surgeries_fields: [
        {
          key: "code",
          label: "Code ",
        },
        {
          key: "date",
          label: "Date",
        },
        {
          key: "side",
          label: "Side ",
        },
      ],
      invoice_fields: [
        {
          key: "serial_no",
          label: "Serial Number",
        },
        {
          key: "financial_category",
          label: "Financial Category",
        },
        {
          key: "date",
          label: "date",
        },
      ],

      planfields: [
        {
          key: "area",
          label: "Area ",
        },
        {
          key: "abbreviation",
          label: "Abbreviation ",
        },
        {
          key: "side",
          label: "Side ",
        },
      ],
      review_status: [
        {
          id: 0,
          type: "Compliant",
        },
        {
          id: 1,
          type: " Non-Compliant",
        },
        {
          id: 2,
          type: " Missed",
        },
      ],

      case_review: {
        treatment_plan: null,
        treatment_plan_type: null,
        operative_procedure: null,
        operative_procedure_type: null,
        attending_note: null,
        attending_note_type: null,
        investigations: null,
        investigation_types: null,
        diagnosis: null,
        diagnosis_type: null,
        dr_name: null,
        dr_name_type: null,
        chief_complaint: null,
        chief_complaint_type: null,
      },
    };
  },

  methods: {
    move(tabIndex) {
      console.log(tabIndex);
    },

    init() {
      this.$store.dispatch("patients/get", this.id).then((response) => {
        // if (response.data.case_review !== null) {
        //   this.case_review = response.data.case_review;
        // }
      });
    },

    // SaveReview() {
    //   this.case_review.case_id = this.id;
    //   console.log(this.case_review);
    //   this.$store
    //     .dispatch("case_review/put", {
    //       id: this.review_id,
    //       query: this.case_review,
    //     })
    //     .then((_) => {
    //       this.$swal({
    //         icon: "success",
    //         title: this.$t("Global.Saved"),
    //         showConfirmButton: false,
    //         timer: 1500,
    //       });
    //     })
    //     .catch((error) => {
    //       this.errorsdata = this.handleBackendError(error.response.data.errors);
    //     });
    // },
  },

  computed: {
    ...mapGetters({
      patient_case: "patients/item",
      load: "patients/load",
    }),

    // review_id() {
    //   if (this.$store.getters["cases/item"].case_review !== null) {
    //     return this.$store.getters["cases/item"].case_review.id;
    //   } else {
    //     return null;
    //   }
    // },
    id() {
      return this.$route.params.id ? this.$route.params.id : null;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.size-min {
  font-size: 10px;
}

.custom_last_table tbody tr th {
  width: 20%;
}
</style>
